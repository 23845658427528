import { Theme } from '@theme/styled';

const colors = {
  green: {
    light: '#73b559',
    medium: '#256d08',
    dark: '#265214',
  },
  black: '#111',
  blue: {
    light: '#6ab0f9',
    medium: '#6ab0f9',
    dark: '#483cf1',
  },
  gray: {
    lightest: '#faf8f5',
    light: '#e2dedc',
    medium: '#aaa5a3',
    dark: '#75706b',
    darkest: '#463b36',
  },
  red: {
    light: '#ffeeea',
    medium: '#ed6c4a',
    dark: '#ba3816',
  },
  white: '#fff',
};

const theme: Theme = {
  color: {
    primary: colors.green.dark,
    secondary: colors.green.medium,
    tertiary: colors.green.light,
    gray: {
      lightest: colors.gray.lightest,
      light: colors.gray.light,
      medium: colors.gray.medium,
      dark: colors.gray.dark,
      darkest: colors.gray.darkest,
    },
    status: {
      error: {
        light: colors.red.light,
        medium: colors.red.medium,
        dark: colors.red.dark,
      },
    },
    text: {
      heading: colors.green.dark,
      inverse: colors.white,
      body: colors.black,
    },
    disabled: colors.gray.dark,
  },
  spacing: {
    unit: 8,
  },
  shape: {
    borderRadius: {
      small: 4,
      medium: 6,
      large: 32,
    },
  },
};

export default theme;
